import { OrganisationActions, OrganisationActionTypes } from './organisation.actions';
import { CallingState, CallState } from '../../state/app.state';

export interface OrganisationState {
  organisations: any;
  isOrganisationsLoading: boolean;
  callState: CallState;
}

const initialState: OrganisationState = {
  organisations: [],
  isOrganisationsLoading: false,
  callState: CallingState.INIT
};

export function organisationReducer(state = initialState, action: OrganisationActions): OrganisationState {

  switch (action.type) {

    case OrganisationActionTypes.LOAD_ORGANISATIONS:
      return {
        ...state,
        isOrganisationsLoading: true
      };
    case OrganisationActionTypes.LOAD_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        isOrganisationsLoading: false,
        organisations: action.organisations
      };
    case OrganisationActionTypes.LOAD_ORGANISATIONS_FAILURE:
      return {
        ...state,
        isOrganisationsLoading: false
      };
    case OrganisationActionTypes.DELETE_ORGANISATION:
      return {
        ...state,
        callState: CallingState.CALLING
      };
    case OrganisationActionTypes.DELETE_ORGANISATION_SUCCESS:
      return {
        ...state,
        callState: CallingState.CALLED
      };
    case OrganisationActionTypes.DELETE_ORGANISATION_FAILURE:
      return {
        ...state,
        callState: {errorMsg: action.error}
      };
    default: {
      return state;
    }
  }
}
