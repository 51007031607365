import { Action } from '@ngrx/store';

export enum OrganisationActionTypes {
  LOAD_ORGANISATIONS = '[Organisation] Load Organisations',
  LOAD_ORGANISATIONS_SUCCESS = '[Organisation] Load Organisations success',
  LOAD_ORGANISATIONS_FAILURE = '[Organisation] Load Organisations failure',
  DELETE_ORGANISATION = '[Organisation] Delete Organisation',
  DELETE_ORGANISATION_SUCCESS = '[Organisation] Delete Organisation success',
  DELETE_ORGANISATION_FAILURE = '[Organisation] Delete Organisation failed'
}

export class LoadOrganisations implements Action {
  readonly type = OrganisationActionTypes.LOAD_ORGANISATIONS;

  constructor() {
  }
}

export class LoadOrganisationsSuccess implements Action {
  readonly type = OrganisationActionTypes.LOAD_ORGANISATIONS_SUCCESS;

  constructor(public organisations: any) {
  }
}

export class LoadOrganisationsFailure implements Action {
  readonly type = OrganisationActionTypes.LOAD_ORGANISATIONS_FAILURE;

  constructor(public error: any) {
  }
}

export class DeleteOrganisation implements Action {
  readonly type = OrganisationActionTypes.DELETE_ORGANISATION;

  constructor(public organisationId: string) {
  }
}

export class DeleteOrganisationSuccess implements Action {
  readonly type = OrganisationActionTypes.DELETE_ORGANISATION_SUCCESS;

  constructor(public organisationId: string) {
  }
}

export class DeleteOrganisationFailure implements Action {
  readonly type = OrganisationActionTypes.DELETE_ORGANISATION_FAILURE;

  constructor(public error: any) {
  }
}

export type OrganisationActions =
  LoadOrganisations
  | LoadOrganisationsSuccess
  | LoadOrganisationsFailure
  | DeleteOrganisation
  | DeleteOrganisationSuccess
  | DeleteOrganisationFailure;


